<template>
  <sgv-table
    ref="docList"
    :rKey="rKey"
    :items="items"
    :headers="headers"
    :toolbars="toolbars"
    :filter.sync="filter"
    :options.sync="options"
    :rowsPerPage="rowsPerPage">

    <template slot-scope="{item, hidden}">
      <tr>
        <td v-if="hidden.code">
          <router-link
            class="text-decoration-none"
            :to="toDetail(item.id)">
            {{item.contact.code}}
          </router-link>
        </td>
        <td v-if="hidden.name">
          {{item.contact.name}}
        </td>
        <td v-if="hidden.parent">
          {{item.parent.name}}
        </td>
      </tr>
    </template>

    <router-link
      slot="action"
      v-if="$auth.hasRole(`doc:${docType}:config`)"
      :to="toDetail(0)">
      <button
        type="button"
        class="btn btn-link text-success">
        เพิ่ม
      </button>
    </router-link>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_DOC_CONFIG_EMPLOYEE } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      detailView: `Doc${this.$form.capitalize(this.docType)}DocConfigDetailEmployee`,
      rKey: `Doc${this.$form.capitalize(this.docType)}DocConfigListEmployee`,
      headers: [
        {text: 'รหัส', value: 'code', sort: true, filter: true},
        {text: 'ชื่อ', value: 'name', sort: true, filter: true},
        {text: 'ตำแหน่ง', value: 'parent'},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name', 'parent'],
        column: null,
        search: null,
        toolbar: null,
      },
      rFields: ['filter', 'options'],
      items: [],
    }
  },
  computed: {
    toolbars () {
      const arr = [
        {value: 'filter', icon: 'cog', class: 'text-warning'},
      ]
      return arr
    },
    rowsPerPage () {
      return [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
      ]
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_DOC_CONFIG_EMPLOYEE(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          docType: this.docType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    getFilter (v) {
      return v
    },
    toDetail (id) {
      return {
        name: this.detailView,
        params: {docConfigId: id}
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
